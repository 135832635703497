import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { sendEventLogRequest } from '../../actions/eventActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import UploadDocumentsComponent from './UploadDocumentsComponent';

const ClaimSubmitConfirmation = ({ claim, networkMonitor }) => {
  const { logId, claimNumber, confirmationId } = claim;
  const { deviceType } = getDeviceTypeInfo();

  useEffect(() => {
    sessionStorage.removeItem('notValidated');
    window.scrollTo(0, 0);
    var event = {
      logId: logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Submit Confirmation'}",
    };

    sendEventLogRequest(event);
  }, []);

  const SuccessMessage = ({ claimNumber }) => (
    <>
      <Typography variant='h1' align='center' color='primary' gutterBottom>
        Your claim has been assigned to an Adjuster
      </Typography>
      <Typography variant='subtitle1' align='center' gutterBottom>
      Our goal is to make contact within 2-3 business days. Please note that you may receive an email or text with your adjuster information. If you are in an area impacted by a catastrophe, please allow additional time for initial contact.
      </Typography>
      <Box pt={3} pb={10}>
        <Typography variant='subtitle1' align='center' gutterBottom>
          Claim Number
        </Typography>
        <Typography variant='h4' align='center' gutterBottom>
          {claimNumber}
        </Typography>
        <Typography gutterBottom variant='subtitle1' align='center'>
          Keep this ID for your reference in case we need to look up your
          submission information during the claims process. Thank you for
          choosing American Modern Insurance Group.
        </Typography>
      </Box>
      <UploadDocumentsComponent />
    </>
  );

  const ErrorMessage = ({ confirmationNumber }) => (
    <>
      <Typography variant='h1' align='center' color='primary' gutterBottom>
        Your claim has been received
      </Typography>
      <Typography variant='subtitle1' align='center' gutterBottom>
        One of our claim representatives will be in contact soon. Should you
        have any emergency needs or concerns, please call us at (800) 375-2075.
      </Typography>
      <Box pt={3} pb={10}>
        <Typography variant='subtitle1' align='center' gutterBottom>
          Confirmation Number
        </Typography>
        <Typography variant='h4' align='center' gutterBottom>
          {confirmationId}
        </Typography>
        <Typography gutterBottom variant='subtitle1' align='center'>
          Keep this ID for your reference in case we need to look up your
          submission information during the claims process. Thank you for
          choosing American Modern Insurance Group.
        </Typography>
      </Box>
    </>
  );

  return (
    
      <Box pt={2}>
        <>
          {claimNumber !== '' ? (
            <>
            <SuccessMessage claimNumber={claimNumber} />
            </>
          ) : (
            <ErrorMessage confirmationNumber={confirmationId} />
          )}
        </>
      </Box>
    
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
  networkMonitor: state.networkMonitor,
});

ClaimSubmitConfirmation.propTypes = {
  claim: PropTypes.object.isRequired,
  networkMonitor: PropTypes.object.isRequired,
};
export default connect(mapStateToProps)(ClaimSubmitConfirmation);
